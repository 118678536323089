<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
        <h1>Hilfe</h1>
        <h2>
          Benutzerhandbuch zur Bestellung von Vermessungsunterlagen</h2>
        <p>
          <v-row sm="11" xl="9" class="ma-5 mt-1 ml-8">
            <v-icon
                left color="green"
                class="pb-2"
            >mdi-cloud-download
            </v-icon>
            <a target="_blank" href="/VUP%20Benutzerhandbuch_offener%20Zugang.pdf"
               style="text-decoration: none">Benutzerhandbuch als PDF-Dokument herunterladen</a>
          </v-row>
        </p>
        <h2>
          Browsereinstellungen</h2>

        <ul>
          <li class="mb-3">Zur Bestellung von Vermessungsunterlagen muss im verwendeten Browser
            <strong>Javascript</strong>
            aktiviert sein, da dies zur Ausführung
            bestimmter wesentlicher Funktionen in der Portalanwendung benötigt wird.
          </li>
          <li class="mb-3">Einige Funktionen des Portals öffnen neue Browserfenster, beispielsweise zur Darstellung von
            Abfrageergebnissen.
            Ein aktivierter PopUp-Blocker (unabhängig ob browserintern oder über ein externes Programm) kann das Öffnen
            des Fensters verhindern.
            Bitte stellen Sie sicher, dass für das Portal <strong>kein PopUp-Blocker aktiviert</strong> ist.
          </li>
          <li class="mb-3">Für die fehlerfreie Nutzung des Portals muss in Ihrem Browser die Verwaltung von <strong>Cookies
            erlaubt</strong> und aktiviert sein.
          </li>
        </ul>
        <h1 style="margin-top: 80px">Kontakt</h1>

        <p class="mt-4">
          Bei fachlichen Fragen zu den Produkten des Vermessungsunterlagenportal NRW wenden Sie sich bitte an das
          zuständige Katasteramt!
          <br><a
            href="https://www.tim-online.nrw.de/tim-online2/?bg=webatlas_grau&scale=1091955&center=410000,5700000&gfi=true&wms=https://www.wms.nrw.de/geobasis/wms_nw_katasterbehoerden_uebersicht?,nw_katasterbehoerden_uebersicht"
            target="_blank">Anschriften der Katasterämter in Nordrhein-Westfalen</a>
        </p>
        <p class="pt-4">
          Bezirksregierung Köln - Geobasis NRW - Wir helfen Ihnen gerne!<br>
          +49(0)221-147-4994 · +49(0)221-147-4224 · <a
            href="mailto:geobasis@bezreg-koeln.nrw.de&subject=Portal Vermessungsunterlagen NRW - Kontaktanfrage">geobasis@bezreg-koeln.nrw.de</a><br>
          telefonische Sprechzeiten: 08:30h - 15:00h (Mo - Do) · Persönliche Besuche sind nur nach vorheriger Absprache
          möglich
        </p>

      </v-col>
      <v-spacer/>
    </v-row>

  </v-container>
</template>

<script>

export default {

  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {

  }
  ,


}
</script>
